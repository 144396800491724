@import './variables.scss';

.cookie-policy{
    background-color: $primary-color;
    .btn{
        background-color: $dark-color;
        font-weight: normal;
        &:hover{
            background-color: $main-color;
        }
    }
}