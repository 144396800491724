@import './variables.scss';
@import './mixins.scss';

.App_desktop {
    .event-text {
        .border {
            border-color: #AAA !important;
            background: #F7F7F7;
        }

        .header {
            height: auto;
            color: #FFF;

            .content-type {
                background-color: $primary-color;
                margin-bottom: 20px;
            }

            .title {
                font-size: 1.2em;

                p {
                    margin: 0;
                    font-weight: bold;
                }
            }
        }

        .ant-table {
            background: transparent !important;

            .ant-table-cell {
                border-bottom: 1px solid #AAA;
                padding: 16px 16px 16px 0;
            }

            svg {
                color: $primary-color;
                font-size: 2.4em;

                &.custom-icon {
                    font-size: 3.1em;
                }
            }

            .table-column {
                padding: 0 10px;
                width: 50px;
            }
        }

        .comprar-icon {
            line-height: 0;

            svg {
                font-size: 0.95em;
                margin-top: -2px
            }
        }

        .buttons-wrapper {
            .btn {
                font-size: 1rem;
                padding: 10px;
            }
        }

        .ant-table-wrapper {
            .ant-table {
                font-family: 'Cabin', sans-serif;

                .table-column {
                    vertical-align: middle;
                }
            }
        }

        @include funcion350px {
            .header {
                .content-type {
                    font-size: 0.65rem;
                    top: -1px;
                    position: relative;
                    margin-bottom: 13px;
                }

                .title {
                    font-size: 0.8rem;
                    position: relative;
                    top: -6px;
                }
            }

            .ant-table {
                .ant-table-cell {
                    font-size: 0.7em;

                }

                .table-column {
                    width: 10px;
                    padding: 5px;

                    svg {
                        position: relative;
                        font-size: 1.1rem;

                        &.custom-icon {
                            font-size: 1.5rem;
                        }
                    }
                }
            }

            .buttons-wrapper {
                .btn {
                    font-size: 0.61rem;
                    height: 29px;
                    padding: 8px 8px;
                    border-radius: 3px;
                }
            }
        }

        @include funcion187px {
            .buttons-wrapper {
                .btn {
                    font-size: 0.35rem;
                    height: 23px;
                    padding: 5px 8px;
                }
            }
        }

        @media (min-width: 330px) and (max-width: 348px) {
            .buttons-wrapper {
                .btn {
                    font-size: 0.9rem;
                }
            }
        }

        @media (min-width: 188px) and (max-width: 230px) {
            .buttons-wrapper {
                .btn {
                    font-size: 0.45rem;
                }
            }
        }

        @media (min-width: 12px) and (max-width: 148px) {
            .buttons-wrapper {
                .btn {
                    font-size: 0.24rem;
                }
            }
        }
    }

    .list-events {
        padding-top: 70px;
        img{
            cursor: pointer;
        }
    }

    .icon-without-result {
        max-width: 50%;
    }

    .without-results {
        min-height: calc(100vh - 268px);
        .btn {
            padding: 15px 25px;
            font-size: 1rem;
        }
    }
}