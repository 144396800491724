@import './mixins.scss';

.App_mobile .time-out{
    h1{
        font-size: 1.8rem;
        @include funcion350px{
            font-size: 1.3rem;
        }
    }

    button{
        font-size: 1.3rem;
        width: 100%;
        padding: 10px;
        @include funcion350px{
            font-size: 1.1rem;
        }
    }
}