@import './variables.scss';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import './mixins.scss';

.App_mobile .evento-seleccionado {
    color: #333;

    .tomar-datos-asistente {
        margin-bottom: 0px;
        svg {
            color: #666;
        }

    }

    .MuiCheckbox-root {
        margin-bottom: -10px;
        svg {
            color: #666;
        }
    }

    .aclaracion {
        margin-top: 0px;
        line-height: 0;
        font-size: small;
    }

    @include funcion350px {
        font-size: 1rem;
    }

    @include funcion292px{
        font-size: 0.8rem;
    }

    .share-event {
        font-size: 1.3rem!important;
        font-weight: bold;
        @include funcion350px {
            font-size: 1.1rem!important;
        }
    }

    .paso-description {
        font-size: 1rem;
        @media (max-width: 395px) {
            font-size: 0.8rem;
        }

        @include funcion292px{
            font-size: 0.7rem;
        }
    }

    .badge-agotado {
        background-color: $main-color;
        padding: 0.8rem;
        font-size: 1.2rem;
        color: #FFF;
    }

    h4 {
        //background-color: #DDD;
        padding: 5px 15px;
        font-size: 17px;
        font-weight: bold;
        font-family: Arial,Helvetica,sans-serif;;

        @include funcion350px {
            font-size: 1.1rem;
        }
    }

    .hr-info{
        border: 1px solid $primary-color;
        margin-left: 0%;
        width: 100%;
    }

    .hr-info-2 {
        border: 1px solid $primary-color;
    }

    .ant-list-item {
        border-bottom: 0;
    }

    .ant-list-item-meta-description {
        padding-left: 1rem;
        color: rgba(102, 102, 102, 1) !important;
    }

    .fecha-hora-simple {

        @include funcion187px {
            svg {
                font-size: 0.9rem;
            }
        }
    }

    .precios {
        p {
            margin-bottom: 5px;
            font-size: 1rem;
            @include funcion292px{
                font-size: 0.8rem;
            }
        }
    }

    svg {
        font-size: 2.5em;
        @include funcion350px {
            font-size: 2.3rem;
        }
        color: $primary-color
    }

    .select {
        background-color: #FFF;
        padding: 10px;
        border-radius: 5px;
        margin-bottom: 20px;

        @include funcion350px {
            margin-bottom: 7px;
        }
    }

    ul.options {
        width: 100%;

        @include funcion350px {
            padding: 2px;
            min-width: auto;
        }

        li {
            padding: 10px;

            @include funcion350px {
                font-size: 0.95rem;
                padding: 3px;
            }
            &:hover {
                background-color: $primary-color;
                color: #FFF;
            }
        }
        .disable-date:hover {
            background-color: rgb(239, 239, 239)!important;
            color:#000;
            cursor: auto;
        }
        .disable-date {
            background-color: rgb(239, 239, 239)!important;
        }
    }

    .buy-tickets {
        text-align: center;
        padding: 10px;
        /*background-color: #DDD;*/
        margin-top: 14px;
        width: 90%;
        margin-left: 5%;
        border-radius: 10px;
        margin-bottom: 7px;

        button {
            font-size: 1.2em;
            padding: 10px 20px;
            width: 100%;
            font-weight: bold;

            @media (min-width: 330px) and (max-width: 348px) {
                font-size: 0.9rem
            }
        }

        @include funcion350px {
            border-width: 1px;
            margin-bottom: 1px;

            button {
                font-size: 1rem;
                padding: 5px 10px;
                height: 40px;
            }
        }

        @include funcion292px{
            button{
                font-size: 0.9rem;
            }
        }

        @media (min-width: 220px) and (max-width: 270px) {
            button {
                font-size: 0.68rem;
            }
        }

        @media (min-width: 187px) and (max-width: 220px) {
            button {
                font-size: 0.56rem;
            }
        }

        @media (min-width: 129px) and (max-width: 139px) {
            padding: 15px 10px;

            button {
                font-size: 0.35rem;
            }
        }

        @media (min-width: 140px) and (max-width: 187px) {
            padding: 15px 10px;

            button {
                font-size: 0.45rem;
            }
        }

        @media (min-width: 12px) and (max-width: 128px) {
            padding: 10px 5px;

            button {
                font-size: 0.24rem;
            }
        }

        @media (max-width: 220px) {
            width: 83%;
            margin-left: 8%;
        }

    }

    .select.dropdown-toggle {
        border: 2px solid #797979;
        text-align: left;
        padding: 5px;

        &::after {
            float: right;
            margin-top: 10px;
            margin-right: 10px;
        }

        @include funcion350px {
            border-width: 1px;

            &::after {
                margin-top: 5px;
                margin-right: 5px;
                position: absolute;
                right: 0;
            }
        }
    }

    hr {
        border: 1px solid;
        margin-left: 4%;
        width: 92%;

        @include funcion350px {
            border-top: 0;
            margin-bottom: 5px;
        }

        @media (max-width: 220px) {
            width: 82%;
            margin-left: 9%;
        }
    }

    .add-places {
        font-size: 2em;

        button {
            background-color: #e7e7e7;
            color: $primary-color;
            width: 60px;
            font-size: 1em;
            height: 60px;
            position: relative;
        }

        @include funcion350px {
            font-size: 2rem;

            button {
                width: 60px;
                height: 60px;
            }
            .cantidad-lugares{
                font-size: 2rem;
            }
        }

        .spinnerbutton {
            top: -10px;
            left: -10px;
            transform: scale(0.5);

            @include funcion350px {
                top: -29%;
                left: -10%;
                transform: scale(0.4);
                width: 100%;
            }
        }
    }

    .compra-paso-dos,
    .compra-paso-tres,
    .compra-paso-cuatro {
        h4 {
            font-size: 1.3rem;
            text-align: left;
            color: #333;
            font-weight: bold;
        }

        @include funcion350px {
            .h4 {
                font-size: 0.9rem;
            }
        }

        .steps-buttons {
            button {
                padding: 15px 15px;
                font-size: 1.3rem;
                font-weight: normal;
                &:first-child {
                    margin-bottom: 10px;
                }

                svg {
                    font-size: 0.9rem;
                }

                @include funcion350px {
                    font-size: 1rem;
                    padding: 10px;

                    svg {
                        font-size: 0.6rem;
                    }
                }

                @include funcion292px {
                    font-size: 1rem;
                }

                @include funcion187px {
                    font-size: 0.54rem;

                    svg {
                        font-size: 0.3rem;
                    }
                }
            }
        }

        .time-date-wrapper{
            padding-bottom: 2px;
            svg{
                font-size: 1.5rem;
                margin-right:5px;
                margin-left:10px;
            }
        }

        .location-wrapper {
            svg{
                font-size: 1.8rem;
            }
        }

        button {
            svg {
                color: #FFF;
            }
        }

        @include funcion350px {
            margin-left: -2px;
            margin-right: -2px;
            .time-date-wrapper{
                padding-bottom: 2px;
                font-size: 1rem;
                svg{
                    margin-right:5px;
                    margin-left:10px;
                }
            }
        }

        .MuiAutocomplete-root{
            button{
                  svg{
                      color:inherit;
                      font-size: inherit;
                  }
              }

              .MuiAutocomplete-input{
                padding: 0;
              }

             .MuiInputLabel-root{
                top:-7px
             }

             .MuiInputLabel-shrink{
                top:0;
             }
          }
    }

    .compra-paso-tres{
        .steps-buttons{
            button{
                width: 100%;
            }
        }
    }



    .detalle-compra-table {
        .ant-table .ant-table-cell {
            font-size: 0.8rem;
            border-bottom: 1px solid #e7e7e7;
            border-right: 1px solid #e7e7e7;
            border-top: 1px solid #e7e7e7;
            padding: 5px;
            text-align: left;

            @include funcion350px {
                font-size: 0.85rem;
                text-align: left;
            }

            @include funcion292px {
                font-size: 0.6rem;
            }

            @include funcion187px {
                line-break: anywhere;
            }

        }
        .ant-table .ant-table-cell.last-column-paso-3 {
            display: none;
        }
        .ant-table .ant-table-cell.last-column {
            padding: 0;
            padding-left: 5px;
            line-height: 44px;
            align-items: center;
            text-align: center;

            button {
                background: none;
                border: 0;
                //border-left: 1px solid #e7e7e7;
                height: 44px;
                text-align: center;
                //margin-left: 3px;
                margin-right: 2px;
                width: 24px;
                padding: 0;
                //margin-left: 5px;

                @include funcion350px {
                    //width: 15px;
                }

                svg {
                    color: #666;
                    font-size: 1.4rem;
                }

                @include funcion350px {
                    svg {
                        font-size: 1rem;
                    }
                }
            }
        }

        .ant-table th.ant-table-cell {
            background: #FFF;
            text-align: center;
            //text-transform: uppercase;
        }
    }

    .total-detalle-compra {
        font-size: 0.9rem;
        text-align: left;
       //border-bottom: 1px solid #000;
        font-weight: bold;

        @include funcion350px {
            font-size: 0.9rem;

            .ps-2 {
                padding: 0;
            }
        }

        @include funcion292px {
            font-size: 0.7rem;
        }
    }

    .detalle-compra-hr {
        margin-bottom: 0px;
    }

    .compra-paso-tres,
    .compra-paso-cuatro {
        .error {
            color: #990000;
            display: block;
            text-align: left;
            padding-bottom: 15px;
        }



        .buttons-wrapper {
            svg {
                color: #FFF;
                font-size: 1.1rem;
            }
        }

        .buttons-wrapper {
            text-align: center;

            button {
                /* width: 300px; */
            }

            @include funcion350px {
                button {
                    font-size: 0.6rem;
                    width: 100%;
                }
            }

            @include funcion292px {
                button {
                    font-size: 0.5rem;
                }
            }
        }

        .detalle-compra-table {
            .ant-table-cell {
                button {
                    display: none;
                }
            }
        }

    }



    .timer-text {
        font-size: 1.25rem;

        @include funcion350px {
            font-size: 0.8rem;
        }

        @include funcion292px {
            font-size: 1.2rem;
        }

        @include funcion187px {
            font-size: 1rem;
        }
    }

    @include funcion350px {

        .form-control,
        .form-select {
            font-size: 0.8rem;
            padding: 8px !important;
        }
    }

    .social-share-link-icons {
        button {
            @include funcionLandscape {
                svg {
                    font-size: 1.2rem;
                }
            }
        }
    }

    .social-share-link-icons {
        button {
            background-color: $primary-color;
            color: #FFF;
            border: 0;
            border-radius: 30px;
            width: 40px;
            height: 40px;
            text-align: center;
            margin: 5px;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                color: #FFF;
                font-size: 1.5rem;
            }

            @include funcion350px {
                width: 40px;
                height: 40px;

                svg {
                    font-size: 1.8rem;
                }
            }
        }
    }

    .btn-bold {
        font-weight: bold!important;
    }

}

.social-share-link-icons {
    button {
        background-color: #666;
        color: #FFF;
        border: 0;
        border-radius: 30px;
        width: 40px;
        height: 40px;
        text-align: center;
        margin: 5px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            color: #FFF;
            font-size: 1.5rem;
        }

        @include funcion350px {
            width: 20px;
            height: 20px;

            svg {
                font-size: 0.7rem;
            }
        }
    }
}
.cancel-bt {
    background-color: $main-color;
}
.modal-compra-evento-plural {
    .modal-body {
        height: auto;
    }

    .ant-table-wrapper .ant-table-tbody>tr>td {
        padding: 2px 0 2px 5px;
    }

    input {
        padding: 10px;
        margin-right: 5px;
    }

    .cancel-bt {
        background-color: $main-color;
    }

    svg {
        font-size: 1rem;
    }

    .modal-footer {
        border-top: 0;
    }

    @include funcion350px {

        .mt-3,
        .mb-3 {
            margin: 0 !important;
        }

        p {
            font-size: 0.9rem;
        }

        .modal-header {
            padding: 10px 10px;
            height: 60px;

            h5 {
                font-size: 0.9rem;
            }
        }
    }

    .ant-table-cell {


        &:nth-child(3) {
            text-align: right;
        }
    }


}



.medios-de-pago-disclaimer {
    color: $primary-color;
    font-size: 0.8rem;
}



.codigo-descuento {
    button {
        font-size: 1.1rem;

        @include funcion350px {
            font-size: 0.8rem;
        }

        @include funcion292px {
            font-size: 0.65rem;
        }

        @include funcion187px {
            font-size: 0.45rem;
        }
    }
    @include funcion350px {
        .txt-codigo-descuento {
            font-size: 0.9rem!important;
        }
    }
}

.h4.titulo_evento_en_paso {
    font-family: 'Century Gothic';
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 8px;
    text-align: center;
    font-size: 1.7rem;
    p{
        font-size: 1.7rem;
    }

    @include funcion350px {
        margin-top: 15px;
        margin-bottom: 5px;
        font-size: 1.5rem!important;
        p{
            font-size: 1.5rem;
        }
    }
}

@include funcion350px {
    .bt-pagar-tipo-pago {
        font-size: 0.5rem;
    }

    #cardPaymentBrick_container {
        transform: scale(0.65) translate(-40px, -220px);
        height: 470px;
        margin-top: 70px;
    }
}

.ant-empty-normal {
    display: none;
}

.button-wrapper-tipo-plural {
    button {
        font-weight: normal;
    }
}

.compra-paso2-info-text {
    text-align: center;
    font-size: 1rem;
    @include funcion350px {
        font-size: 0.7rem;
    }
}

.subtitle {
    font-size: 1.1rem!important;
    font-weight: bold;

    @include funcion292px{
        font-size: 0.9rem !important;
    }
}

.label-form {
    font-size: 0.9rem!important;
    font-weight: bold;

    @include funcion292px{
        font-size: 0.76rem !important;
    }
}

.datos-asistente {
    font-size: 1rem!important;;
}

.form-datos {
    input {
        @include funcion350px {
         font-size: 1rem!important;
        }
    }
}

.add-places-text {
    @include funcion350px {
        margin-bottom: 3px;
    }
}

.fieldset {
    margin-top: 10px;

    @include funcion350px {
        margin-top: 7px;

    }

    label {
        transition: .2s ease-in-out;
        transform-origin: top left;
        position: absolute;
        top: 10px;
        left: 10px;

        @include funcion350px {
            top: 7px;
            left: 5px;
        }
    }

    input.form-control {
        background: none;
        position: relative;

        @include funcion350px {
            padding: 9px !important;
        }
    }

    &.focus {
        label {
            transform: scale(0.8) translate(-5px, -25px);
            background: #FFF;
            z-index: 1;
            padding: 0 5px;

            @include funcion350px {
                transform: scale(0.8) translate(-3px, -18px);
            }
        }
    }
}

.compra-paso-tres {
    .has-error {
        border: 1px solid #990000
    }
}