@import './variables.scss';
@import './mixins.scss';
.pay-success{
    margin-top: 30px;
    
    .title{
        line-height: initial;
        font-size: 2.5rem;
        @include funcion350px{
            font-size: 2rem;
        }
    }
    .button-container{
        background-color: $main-color;
        color: #FFF;
        border-radius: 10px;
        padding: 15px;
    }

    .logo-confirmado{
        max-width: 90%;
        @include funcion350px{
            max-width: 100%;
        }
    }
}

.codigo-mercado-pago{
    font-size:2rem;
    @include funcion350px{
        font-size: 1rem;
    }
}
.title-pay-succes {
    font-size: 1.5rem;
}

.pay-success-description {
    font-size: 1rem;
    @media (max-width: 395px) {
        font-size: 0.8rem;
    }
   
}

.modal-comment-body {
    height: auto!important;
}
