@import '../variables.scss';
@import '../mixins.scss';


.info-general {
    button {
        background-color: transparent;
        border: 1px solid $primary-color;
        color: #000;
        padding: 7px;
        width: 135px;
        font-size: 0.85rem;
        font-weight: normal;
        @include funcion350px {
            font-size: 0.85rem;
            width: 95px;
            padding: 3px;
        }

        @include funcion292px {
            width: 80px;
            font-size: 0.8rem;
        }
    }

    .buttons-wrapper {
        width: 280px;
        margin-bottom: 20px;

        @include funcion350px {
            margin-bottom: 10px;
        }

        @include funcion187px {
            flex-direction: column;
            align-items: center;

            button {
                margin-bottom: 10px;

                &:first-child:nth-last-child(n+2) {
                    margin-left: 0;
                }
            }
        }
    }

    @include funcion350px {
        p {
            margin-bottom: 5px;
            font-size: 1rem;
        }
    }

    @include funcion292px{
        p{
            font-size: 0.8rem;
        }
    }

    svg {
        margin-bottom: 10px;
    }
}


.evento-seleccionado-modal {
    iframe {
        width: 100%;
        height: 100%;
    }

    .mapa-wrapper {
        height: 100%;
    }

    .modal-body {
        padding: 0;
    }

    &.fotos {
        .modal-body {
            height: auto;
        }
    }
}

.eev-video {
    width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
}

.eev-fotos-modal-body {
    height: auto!important;
}