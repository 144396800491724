@import './variables.scss';
@import './general.scss';

.App_mobile {
    .productores-y-salas {
        section {
            padding: 40px 20px 10px 10px;

            h1.que-es{
                font-size:2.5rem;
                font-weight: bold;
                text-align: right;
                padding-right: 15px;
            }

            h1.ventajas{

            }

            h1.estamos-listos{
                font-size: 20px;
                font-weight: bold;
                padding-bottom: 10px;
            }

            h1.quienes-hacemos{
                font-size:2.2rem;
                font-weight: bold;
            }

            .text-justify{
                text-align: justify;
            }

            .min-font-size{
                font-size:13px;
            }

            .linea{
                background-color: #9a83d7;
                height: 95%;
                width: 2px;
            }
        }


        a {
            padding: 7px 14px;
            border-radius: 7px;
            font-size: 0.9rem;
            text-decoration: none;
            margin-right: 10px;
            margin-bottom: 10px;
            display: inline-block;
            background-color: $primary-color;
            color: #FFF;
            width: 100%;
        }

        .link-smart-link {
            padding: 0;
            border-radius: 0;
            font-size: 0.9rem;
            margin-right: 0;
            margin-bottom: 0;
            text-decoration: underline;
            display: inline-block;
            color: white;
            background-color: transparent;
            width: 100%;
        }

        .link-smart-link-azul {
            padding: 0;
            border-radius: 0;
            font-size: 0.9rem;
            text-decoration: underline;
            color: #380ab4;
            background-color: transparent;
        }

        .phone-link {
            padding: 7px 14px;
            border-radius: 0;
            font-size: 0.9rem;
            text-decoration: underline;
            margin-right: 10px;
            margin-bottom: 10px;
            display: inline-block;
            background-color: white;
            color: blue;
            width: 100%;
        }

        .email-link {
            padding: 7px 14px;
            border-radius: 0;
            font-size: 0.9rem;
            text-decoration: underline;
            margin-right: 10px;
            margin-bottom: 0px;
            display: inline-block;
            background-color: white;
            color: blue;
            width: 100%;
        }

        .icon_box {
            border-radius: 100px;
            background-color: $main-color;
            width: 200px;
            height: 200px;
            margin-bottom: 40px;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
            color: #FFF;
            border: 2px solid $main-color;
            outline: 4px solid $primary-color;
            outline-offset: -7px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            img{
                max-width: 55px;
                margin-bottom: 10px;
            }

            h3{
                margin: 0;
            }
        }

        .face {
            text-align: center;
            margin-top: 40px;

            img {
                max-width: 240px;
                border-radius: 200px;
                outline: 2px solid $primary-color;
                outline-offset: 5px;
                margin-bottom: 20px;
            }

            h2 {
                margin-bottom: 0;
                color: $main-color
            }

            p {
                padding-left: 30%;
                padding-right: 30%;
            }
        }

        .ventajas-back{
            width: 80%;
            left: -20%;
            margin-top: 50px;
            opacity: 0.2;
            position: absolute;
        }

        .scroll {
            width: 50px;
            height: 50px;
            border: 6px solid $primary-color;
            border-radius: 50%;
            position: fixed;
            animation: down 1.5s infinite;
            -webkit-animation: down 1.5s infinite;
            top: 90%;
            right: 50%;
            left: 44%;
            &::before {
                content: '';
                position: absolute;
                top: 11px;
                left: 12px;
                width: 15px;
                height: 15px;
                border-left: 6px solid $primary-color;
                border-bottom: 6px solid $primary-color;
                transform: rotate(-45deg);
            }
        }

        @include funcion350px {
            .scroll {
                width: 50px;
                height: 50px;
                border: 6px solid $primary-color;
                border-radius: 50%;
                position: fixed;
                animation: down 1.5s infinite;
                -webkit-animation: down 1.5s infinite;
                top: 85%;
                right: 50%;
                left: 42%;
                &::before {
                    content: '';
                    position: absolute;
                    top: 11px;
                    left: 12px;
                    width: 15px;
                    height: 15px;
                    border-left: 6px solid $primary-color;
                    border-bottom: 6px solid $primary-color;
                    transform: rotate(-45deg);
                }
            }
        }

        @keyframes down {
            0% {
                transform: translate(0);
            }
            20% {
                transform: translateY(15px);
            }
            40% {
                transform: translate(0);
            }
        }

        @-webkit-keyframes down {
            0% {
                transform: translate(0);
            }
            20% {
                transform: translateY(15px);
            }
            40% {
                transform: translate(0);
            }
        }

    }
}


.animated {
    animation-duration: 1s;
    animation-fill-mode: both;
}

@keyframes bounceInLeft {
    0%,60%,75%,90%,to {
        animation-timing-function: cubic-bezier(.215,.61,.355,1)
    }

    0% {
        opacity: 0;
        transform: translate3d(-3000px,0,0)
    }

    60% {
        opacity: 1;
        transform: translate3d(25px,0,0)
    }

    75% {
        transform: translate3d(-10px,0,0)
    }

    90% {
        transform: translate3d(5px,0,0)
    }

    to {
        transform: translateZ(0)
    }
}

.bounceInLeft {
    animation-name: bounceInLeft
}

@keyframes bounceInRight {
    0%,60%,75%,90%,to {
        animation-timing-function: cubic-bezier(.215,.61,.355,1)
    }

    0% {
        opacity: 0;
        transform: translate3d(3000px,0,0)
    }

    60% {
        opacity: 1;
        transform: translate3d(-25px,0,0)
    }

    75% {
        transform: translate3d(10px,0,0)
    }

    90% {
        transform: translate3d(-5px,0,0)
    }

    to {
        transform: translateZ(0)
    }
}

.bounceInRight {
    animation-name: bounceInRight;
}

@keyframes fadeIn {
    0% {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

.fadeIn {
    animation-name: fadeIn;
}