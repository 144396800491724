@import '../mixins.scss';

.compra-paso-uno {
    @include funcion350px {
        margin-left: -10px;
        margin-right: -10px;
    }

    @media (max-width: 220px) {
        --bs-gutter-x: 5px;
        overflow-x: hidden;
    }
}

.paso-uno-image-wrapper{
    margin-top: 0.2rem;
@include funcionLandscape{
    background-color: #e6e6e6;
    img{
        max-height: 270px;
        margin-left: auto;
        margin-right: auto;
        display: block;
    }
    }
}

.event-data{
    @include funcionLandscape{
        padding: 0;
    }
}

.row-info {
    margin-bottom: 17px;
    .col{
        font-size:1rem;
    }
}