@import "../../node_modules/bootstrap/scss/bootstrap.scss";
@import url('https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
@import "/src/mobile/sass/mixins.scss";
.main-wrapper, .home{
    min-height: calc( 100vh - 270px);
    @include funcion350px {
      min-height: calc( 100vh - 250px);
     }

     @include funcion292px{
      min-height: calc( 100vh - 185px);
     }

     @include funcionLandscape{
      min-height: calc(100vh - 160px);
     }
}

body{
    background-color: #FFFFFF;
    overflow-x: hidden;
    /*font-family: 'Cabin', sans-serif;*/
    font-family: Arial,Helvetica,sans-serif;;
    text-size-adjust: unset;
    font-size: 1rem;
    overflow-x: hidden;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @include funcion350px {
      font-size: 0.5rem;
    }
  }

  p {
    -webkit-text-size-adjust: auto;
    text-size-adjust: auto;
  }


