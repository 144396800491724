@import '../mixins.scss';
@import '../variables.scss';

.App_mobile {
    .compra-paso-cuatro {
        .has-error {
            color: #F23D4F;

            input {
                border: 1px solid #F23D4F;
                color: #000;
            }

            .error {
                color: #F23D4F;
            }
        }

        .dibujo-tarjeta {
            position: absolute;
            right: 5px;
            bottom: 5px;

            @include funcion350px {
                transform: scale(0.45) translate(25px, 17px);
            }

        }



        .pagar-decidir {
            width: 100%;
            background-image: linear-gradient(90deg,
                    #009ee3 0%,
                    #009ee3 50%,
                    #005e88 50%,
                    #005e88 100%);
            background-size: 200%;
            transition: background-position 2s cubic-bezier(0.47, 0.1, 1, 0.63), color 0.5s linear;
            transition-delay: 0s, 0.25s;

            @include funcion350px {
                padding: 0 !important;
                height: 40px;
                font-size: 0.7rem;

                svg {
                    width: 12px;
                    height: 12px;
                }
            }
        }

        .mensaje-error {
            padding: 16px;
            box-sizing: border-box;
            font-size: 14px;
            border-radius: 6px;
            align-items: center;
            display: flex;
            border-left: 6px solid #f23d4f;
            width: 100%;
            margin-bottom: 24px;
            background-color: #f5f5f5;
            cursor: pointer;
            justify-content: space-between;
            color: #1a1a1a;

            p {
                margin: 0;
                width: 100%;
                padding-left: 16px;
            }
        }

        .form-select {
            @include funcion350px {
                background: none;
            }
        }

        .medios-de-pago {
            background-color: $dark-color;
            font-size: 0.9rem;

            @include funcion350px {
                font-size: 0.8rem;
                padding: 8px;
            }
        }

        #card-container {
            .h5 {
                color: #000;
                font-size: 1.125rem;
                text-align: left;
                margin-bottom: 20px;

                @include funcion350px {
                    font-size: 0.7rem;
                }
            }

            .icons-small {
                display: flex;
                justify-content: left;
                align-items: flex-start;
                padding-bottom: 40px;

                @include funcion350px {
                    transform: scale(0.8) translate(-16px, -10px);
                    padding-bottom: 0;
                }

                div {
                    width: 36px;
                    height: 24px;
                    border: 1px solid #E5E5E5;
                    display: flex;
                    justify-content: center;
                    border-radius: 4px;
                    margin-right: 4px;
                    align-items: center;

                    svg {
                        max-width: 35px;
                        max-height: 24px;
                    }
                }
            }

            label {
                width: 100%;
                margin-bottom: 0.2rem;
            }

            input {
                border-radius: 5px;
                @include funcion350px {
                    font-size: 1rem!important;
                }
            }

            .fieldset {
                margin-top: 0px;
            }

            .input-group-text {
                padding: 0;
                background: #FFF;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;

                select {
                    border: 0;
                }
            }

            #document {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }

            .error {
                width: 100%;
            }
        }

        .error-mercado-pago {
            font-size: 1rem;
            color: #FFF;
            margin-top: -20px;
            position: relative;
            z-index: 2;
            margin-bottom: 20px;
            padding: 10px;
        }

        .cuotas {
            .input-group-text {
                width: 100%;
            }
        }

        .submit-credit-card-button {
            width: 100%;
            @include funcion350px {
                width: auto;
            }
        }
    }

    .pagar-btn {
        text-align: center;
        font-weight: bold;
        button {
            font-size: 1.2em;
            padding: 10px 20px;
            width: 100%;

            @media (min-width: 330px) and (max-width: 348px) {
                font-size: 0.9rem
            }
        }

        @include funcion350px {
            border-width: 1px;
            margin-bottom: 1px;

            button {
                font-size: 1rem;
                padding: 5px 10px;
                height: 40px;
            }
        }

        @media (min-width: 220px) and (max-width: 270px) {
            button {
                font-size: 0.68rem;
            }
        }

        @media (min-width: 187px) and (max-width: 220px) {
            button {
                font-size: 0.56rem;
            }
        }

        @media (min-width: 129px) and (max-width: 139px) {
            padding: 15px 10px;

            button {
                font-size: 0.35rem;
            }
        }

        @media (min-width: 140px) and (max-width: 187px) {
            padding: 15px 10px;

            button {
                font-size: 0.45rem;
            }
        }

        @media (min-width: 12px) and (max-width: 128px) {
            padding: 10px 5px;

            button {
                font-size: 0.24rem;
            }
        }

        @media (max-width: 220px) {
            width: 83%;
            margin-left: 8%;
        }

    }

}