@import './variables.scss';
@import './mixins.scss';

html {
    -moz-text-size-adjust: auto;
    -webkit-text-size-adjust: auto;
    text-size-adjust: auto;
  }

.btn{
    background-color: $primary-color;
    border: 0;
    &.btn-link{
        background-color: transparent;
    }
}

#root{
    overflow-x: hidden;
}

.back_1{
    background-color: #FFF;
}
.back_2{
    background-color: $main-color;
}
.back_3{
    background-color: $primary-color;
}

.btn-back {
    font-size: 1rem;
    padding: 10px;
    @include funcion350px{
        font-size: 0.8rem;
        height: 34px;
        padding: 8px 8px;
        border-radius: 3px;
    }
}

.text_1{
    color: #FFF;
}
.text_2{
    color: $main-color;
}
.text_3{
    color: $primary-color;
}

.fs-11{
    font-size:1.1em;
}

.fs-12{
    font-size:1.2em;
}
.fs-22{
    font-size:2.2em;
}

.App_mobile .down-icon {
    width: 30px;
    height: 30px;
    background-color: $primary-color;
    position: relative;
    right: 8px;
    color: #FFF;
    position: absolute;
    svg.MuiSvgIcon-root{
        color:#FFF;
        font-size:1.9rem;
    }

    @include funcion350px{
        width: 25px;
        height: 25px;
        svg.MuiSvgIcon-root{
            font-size:1.5rem;
            position: relative;
            top:1px;
            left:0.5px;
        }
    }


}

.App_mobile .abajo-icon {
    width: 30px;
    height: 30px;
    background-color: $main-color;
    position: relative;
    right: 8px;
    color: #FFF;
    position: absolute;
    svg.MuiSvgIcon-root{
        color:#FFF;
        font-size:1.9rem;
    }

    @include funcion350px{
        width: 25px;
        height: 25px;
        svg.MuiSvgIcon-root{
            font-size:1.5rem;
            position: relative;
            top:1px;
            left:0.5px;
        }
    }


}

.App_mobile input:focus {
    outline: none !important;
    box-shadow: none !important;
}

.App_mobile .btn:first-child:active, .btn:hover, .btn:focus-visible{
    background-color: $primary-color;
}

.App_mobile .general-title{
    font-size:1.8rem;
    @include funcion350px{
        font-size: 1.3rem;
    }
}


.social-wrapper .down-icon{
    @media (min-width: 12px) and (max-width: 177px) {
        margin-bottom:60px;
        right:-20px
     }
}

.btn{
    font-weight: bold;
}

p{
    @include funcion350px{
        font-size: 0.9rem;
    }
}

.App_mobile .modal-error{
    background: rgba(0,0,0, 0.5);
    height: 100vh;
    margin: 0;
    padding: 10px;
    width: 100%;
}

.App_mobile {
    .background-image-landing {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: contain;
        /* background-position: center; */
        z-index: -1;
      }
      
      .floating-button-landing {
        position: fixed;
        bottom: 270px;
        right: 40px;
        z-index: 1;
        width: 43%;
        height: 46px;
        font-size: 1.3rem;
        @include funcion350px{
            font-size: 1rem;
            bottom: 270px;
            width: 44%;
        }
      }
}