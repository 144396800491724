@import './variables.scss';

.App_desktop {
    .header {
        background-color: $main-color;
        min-height: 70px;

        .btn-abrir-cartelera {
            border: 2px solid;
            border-radius: 10px;
            width: 150px;
            height: 40px;

            span {
                color: #FFF;
                font-size: 14px;
                white-space: nowrap;
                padding: 0;
            };

        }

        .help-icon {
            font-size: 2.2em;
        }

        .logo {
            max-height: 45px;
            margin-bottom: 10px;
            margin-top: 5px;
        }

        &.fija {
            position: fixed;
            width: 100%;
            z-index: 2;
        }

        .spinner-wrapper {
            height: 200px;
        }

        .btn-ayuda{
            font-size: 14px;
            border:1px solid #FFF;
            background-color: $main-color;
            &:hover {
                background-color: $primary-color;
            }
        }
    }
    .dd-menu{
        height: 50px;
        margin-top: 10px;
        svg{
            margin-top:10px
        }
    }
    .submenu {
        background-color: #241968;
        color: white;
        width: 215px;
        height: auto;
        overflow: auto;
        border-radius: 0;
        border-bottom: 1px solid #FFF;
        border-right: 1px solid #FFF;
        max-height: calc(100vh - 65px);
        padding-bottom: 20px;
        padding-top: 0;

        .submenu-title {
            background-color: #FFF;
            color: #241968;
            padding-top: 5px;
        }

        a {
            color: #FFF;
            text-decoration: none;
            font-size: 0.9em;
        }

        li{
            cursor: pointer;
        }
    }
}