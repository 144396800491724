@import './variables.scss';
@import './mixins.scss';

.App_desktop .pasoscompra{
    .paso {
        background-color: #E6E6E6;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size:1rem;
        margin-left: 3px;
        line-height: 20px;
        @include funcion350px{
            height: 35px;
            font-size:1rem;
        }
    }

    .p-0:nth-child(1){
        .paso{
            margin-left:0
        }
    }

    .paso-actual {
        background-color: $primary-color;
        color: #FFF;
        font-size:1em;
        text-align: center;
        padding: 5px;
        @include funcion350px{
            font-size: 0.8rem;
            line-height: 1.2em;
        }
        @media (max-width: 220px){
            font-size: 0.55rem;
        }
    }

    .proximamente{
        font-size:2.4rem;
        color: $primary-color;
        font-family: 'Century gothic';
        font-weight: bold;
        letter-spacing: 0.1rem;
    }
}