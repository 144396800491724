@import '../mixins.scss';
@import '../variables.scss';

.App_desktop {
    .compra-paso-cuatro {
        .steps-buttons {
            button {
                width: 360px;
                margin-bottom: -2px;
            }
        }

        .has-error {
            /*color: #F23D4F;*/

            input {
                border: 1px solid #F23D4F;
                color: #000;
            }

            .error {
                /*color: #F23D4F;*/
            }
        }

        .dibujo-tarjeta {
            position: absolute;
            right: 5px;
            bottom: 5px;

            @include funcion350px {
                transform: scale(0.45) translate(25px, 17px);
            }

        }

        .pagar-decidir {
            width: 260px;
            padding: 0;
            margin: 0;
            height: 40px;
            line-height: 0px;
            font-size: 1.4rem;
        }

        .mensaje-error {
            padding: 16px;
            box-sizing: border-box;
            font-size: 14px;
            border-radius: 6px;
            align-items: center;
            display: flex;
            border-left: 6px solid #f23d4f;
            width: 100%;
            margin-bottom: 24px;
            background-color: #f5f5f5;
            cursor: pointer;
            justify-content: space-between;
            color: #1a1a1a;

            p {
                margin: 0;
                width: 100%;
                padding-left: 16px;
            }
        }

        .form-select {
            @include funcion350px {
                background: none;
            }
        }

        .medios-de-pago {
            background-color: $dark-color;
            font-size: 0.7rem;

            @include funcion350px {
                font-size: 0.6rem;
                padding: 5px;
            }
        }

        #card-container {
            .h5 {
                color: #000;
                font-size: 1.125rem;
                text-align: left;
                margin-bottom: 20px;

                @include funcion350px {
                    font-size: 0.7rem;
                }
            }

            .icons-small {
                display: flex;
                justify-content: left;
                align-items: flex-start;
                padding-bottom: 40px;

                @include funcion350px {
                    transform: scale(0.8) translate(-16px, -10px);
                    padding-bottom: 0;
                }

                div {
                    width: 36px;
                    height: 24px;
                    border: 1px solid #E5E5E5;
                    display: flex;
                    justify-content: center;
                    border-radius: 4px;
                    margin-right: 4px;
                    align-items: center;

                    svg {
                        max-width: 35px;
                        max-height: 24px;
                    }
                }
            }

            label {
                width: 100%;
            }

            input {
                border-radius: 5px;
            }

            .input-group-text {
                padding: 0;
                background: #FFF;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;

                select {
                    border: 0;
                }
            }

            #document {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }

            .error {
                width: 100%;
            }
        }

        .error-mercado-pago {
            font-size: 1rem;
            color: #FFF;
            margin-top: -20px;
            position: relative;
            z-index: 2;
            margin-bottom: 20px;
            padding: 10px;
        }

        .codigo-seguridad-vencimiento {
            .form-group {
                flex: 0.27 0;
            }
        }

        .nombre-dni {
            .form-group {
                flex: 0.55 0;
            }
        }

        .cuotas {
            label {
                margin-bottom: 0;
            }
        }

    }
}