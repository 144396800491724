@import './variables.scss';
@import './mixins.scss';

.App_mobile {
    .event-text {
        .border {
            border-color: #AAA !important;
            background: #F7F7F7;
        }

        .header {
            height: auto;
            color: #FFF;

            .content-type {
                background-color: $primary-color;
                margin-bottom: 20px;
                font-size: 18px;
            }

            .title {
                font-size: 1.3em;

                p {
                    margin: 0;
                    font-weight: bold;
                }

                @include funcionLandscape{
                    font-size:1em
                }
            }
        }

        .ant-table {
            background: transparent !important;

            .ant-table-cell {
                border-bottom: 1px solid #AAA;
                padding: 16px 16px 16px 0;
                font-size: 18px;
            }

            svg {
                color: $primary-color;
                font-size: 2.4em;

                &.custom-icon {
                    font-size: 3.1em;
                }
            }

            .table-column {
                padding: 0 10px;
                width: 50px;
            }
        }

        .comprar-icon {
            line-height: 0;

            svg {
                font-size: 0.95em;
                margin-top: -2px
            }
        }

        .buttons-wrapper {
            .btn {
                font-size: 0.93rem;
                padding: 10px;
                @include funcionLandscape{
                font-size: 0.7rem;
                }
            }
        }

        .ant-table-wrapper {
            .ant-table {
                font-family: 'Cabin', sans-serif;

                .table-column {
                    vertical-align: middle;
                }
            }
        }

        @include funcion350px {
            .header {
                .content-type {
                    font-size: 0.8rem;
                    top: -1px;
                    position: relative;
                    margin-bottom: 13px;
                }

                .title {
                    font-size: 1.1rem;
                    position: relative;
                    top: -6px;
                }
            }

            .ant-table {
                .ant-table-row {
                    line-height: 1.3;
                }
                .ant-table-cell {
                    font-size: 0.7em;
                    .text-data{
                        font-size: 0.85rem!important;
                        line-height: 1;
                    }
                }

                .table-column {
                    width: 10px;
                    padding: 5px;

                    svg {
                        position: relative;
                        font-size: 1.1rem;

                        &.custom-icon {
                            font-size: 1.5rem;
                        }
                    }
                }
            }

            .buttons-wrapper {
                .btn {
                    font-size: 0.8rem;
                    height: 34px;
                    padding: 8px 8px;
                    border-radius: 3px;
                }
            }
        }

        @include funcion187px {
            .buttons-wrapper {
                .btn {
                    font-size: 0.35rem;
                    height: 23px;
                    padding: 5px 8px;
                }
            }
        }

        @media (min-width: 330px) and (max-width: 348px) {
            .buttons-wrapper {
                .btn {
                    font-size: 0.9rem;
                }
            }
        }

        @media (min-width: 188px) and (max-width: 230px) {
            .buttons-wrapper {
                .btn {
                    font-size: 0.45rem;
                }
            }
        }

        @media (min-width: 12px) and (max-width: 148px) {
            .buttons-wrapper {
                .btn {
                    font-size: 0.24rem;
                }
            }
        }
    }

    .list-events {
        padding-top: 70px;

        @include funcion350px {
            padding-top: 55px;
        }

        @include funcionLandscape{
        }
    }


}