@import './variables.scss';
@import './general.scss';

.App_desktop {
    .productores-y-salas {

            h1,
            .h1 {
                font-size: 3.5em;
                font-weight: bold;
            }

            h2 {
                font-size: 2.8em;
            }

            h3{
                font-size: 2.2em;
            }

            h4{
                font-size:1.4em
            }


        a {
            border: 1px solid;
            text-transform: uppercase;
            padding: 7px;
            border-radius: 7px;
            font-size: 0.8em;
            text-decoration: none;
            margin-right: 10px;
            margin-bottom: 20px;
            display: inline-block;
        }

        .phone-link {
            border: none;
            text-transform: uppercase;
            padding: 0;
            border-radius: 0;
            font-size: 1em;
            text-decoration: none;
            margin-right: 10px;
            margin-bottom: 20px;
            display: inline-block;
        }
        .link-smart-link {
            text-transform: none;
            border: none;
            padding: 0;
            border-radius: 0;
            font-size: 1em;
            text-decoration: underline;
            margin-right: 10px;
            margin-bottom: 20px;
            display: inline-block;
            color: #fff;
        }
        .link-smart-link-azul {
            text-transform: none;
            border: none;
            padding: 0;
            border-radius: 0;
            font-size: 1em;
            text-decoration: underline;
            margin-right: 10px;
            margin-bottom: 20px;
            display: inline-block;
            color: #380ab4;
        }
        .email-link {
            border: none;
            text-transform: uppercase;
            padding: 0;
            border-radius: 0;
            font-size: 1em;
            text-decoration: none;
            margin-right: 10px;
            margin-bottom: 0px;
            display: inline-block;
        }

        .margin-top {
            margin-top:  -30px !important;
        }

        .icon_box {
            border-radius: 100px;
            background-color: $main-color;
            width: 200px;
            height: 200px;
            margin-bottom: 40px;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
            color: #FFF;
            border: 2px solid $main-color;
            outline: 4px solid $primary-color;
            outline-offset: -7px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
            padding-bottom: 40px;
            img{
                max-width: 40px;
            }
            .icon-box-value {
                font-size: 1.8em;
            }
            .icon-entradas-vendidas {
                max-width: 50px;
            }
        }

        .face {
            text-align: center;
            margin-top: 40px;
            width: 250px;
            img {
                max-width: 240px;
                border-radius: 200px;
                outline: 2px solid $primary-color;
                outline-offset: 5px;
                margin-bottom: 20px;
            }

            h2 {
                margin-bottom: 0;
                color: $main-color
            }

            p {
                padding-left: 10%;
                padding-right: 10%;
            }
        }
        .scroll {
            width: 55px;
            height: 55px;
            border: 6px solid $primary-color;
            border-radius: 50%;
            position: fixed;
            animation: down 1.5s infinite;
            -webkit-animation: down 1.5s infinite;
            top: 80%;
            right: 50%;
            left: 46%;
            &::before {
                content: '';
                position: absolute;
                top: 11px;
                left: 13px;
                width: 18px;
                height: 18px;
                border-left: 6px solid $primary-color;
                border-bottom: 6px solid $primary-color;
                transform: rotate(-45deg);
            }
        }
    }
}

.animated {
    animation-duration: 1s;
    animation-fill-mode: both;
}

@keyframes bounceInLeft {
    0%,60%,75%,90%,to {
        animation-timing-function: cubic-bezier(.215,.61,.355,1)
    }

    0% {
        opacity: 0;
        transform: translate3d(-3000px,0,0)
    }

    60% {
        opacity: 1;
        transform: translate3d(25px,0,0)
    }

    75% {
        transform: translate3d(-10px,0,0)
    }

    90% {
        transform: translate3d(5px,0,0)
    }

    to {
        transform: translateZ(0)
    }
}

.bounceInLeft {
    animation-name: bounceInLeft
}

@keyframes bounceInRight {
    0%,60%,75%,90%,to {
        animation-timing-function: cubic-bezier(.215,.61,.355,1)
    }

    0% {
        opacity: 0;
        transform: translate3d(3000px,0,0)
    }

    60% {
        opacity: 1;
        transform: translate3d(-25px,0,0)
    }

    75% {
        transform: translate3d(10px,0,0)
    }

    90% {
        transform: translate3d(-5px,0,0)
    }

    to {
        transform: translateZ(0)
    }
}

.bounceInRight {
    animation-name: bounceInRight;
}

@keyframes fadeIn {
    0% {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

.fadeIn {
    animation-name: fadeIn;
}