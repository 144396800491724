.progress {
    background-color: rgb(229,233,235);
    height: 1em;
    position: relative;
    width: 100%;
}
.progress-bar-red {
    background-size: 23em 0.25em;
    height: 100%;
    position: relative;
    background-color: #e04542;
    animation: cssload-width 7s cubic-bezier(0.45, 0, 1, 1) ;
        -o-animation: cssload-width 7s cubic-bezier(0.45, 0, 1, 1) ;
        -ms-animation: cssload-width 7s cubic-bezier(0.45, 0, 1, 1) ;
        -webkit-animation: cssload-width 7s cubic-bezier(0.45, 0, 1, 1) ;
        -moz-animation: cssload-width 7s cubic-bezier(0.45, 0, 1, 1) ;
}


@keyframes cssload-width {
    0%, 100% {
        transition-timing-function: cubic-bezier(1, 0, 0.65, 0.85);
    }
    0% {
        width: 0;
    }
    100% {
        width: 100%;
    }
}

@-o-keyframes cssload-width {
    0%, 100% {
        -o-transition-timing-function: cubic-bezier(1, 0, 0.65, 0.85);
    }
    0% {
        width: 0;
    }
    100% {
        width: 100%;
    }
}

@-ms-keyframes cssload-width {
    0%, 100% {
        -ms-transition-timing-function: cubic-bezier(1, 0, 0.65, 0.85);
    }
    0% {
        width: 0;
    }
    100% {
        width: 100%;
    }
}

@-webkit-keyframes cssload-width {
    0%, 100% {
        -webkit-transition-timing-function: cubic-bezier(1, 0, 0.65, 0.85);
    }
    0% {
        width: 0;
    }
    100% {
        width: 100%;
    }
}

@-moz-keyframes cssload-width {
    0%, 100% {
        -moz-transition-timing-function: cubic-bezier(1, 0, 0.65, 0.85);
    }
    0% {
        width: 0;
    }
    100% {
        width: 100%;
    }
}