@mixin funcion350px {
  @media (max-width: 330px) {
    @content; // Incluye el contenido del mixin dentro de la media query
  }
}

@mixin funcion292px {
  @media (max-width: 292px) {
    @content; // Incluye el contenido del mixin dentro de la media query
  }
}

@mixin funcion235px {
  @media (max-width: 235px) {
    @content; // Incluye el contenido del mixin dentro de la media query
  }
}

@mixin funcion187px {
  @media (max-width: 187px) {
    @content; // Incluye el contenido del mixin dentro de la media query
  }
}

@mixin funcionLandscape {
  @media screen and (min-width: 220px) and (max-width: 896px) and (orientation: landscape) {
    @content;
  }
}

