@import '../variables.scss';
@import '../mixins.scss';

.App_desktop{
.info-general {
    button {
        background-color: transparent;
        border: 1px solid $primary-color;
        color: #000;
        padding: 7px;
        width: 135px;
        font-size: 0.95rem;
        font-weight: normal;

        @include funcion350px {
            font-size: 0.8rem;
            width: 95px;
            padding: 3px;
        }

        @include funcion292px {
            width: 80px;
            font-size: 0.5rem;
        }
    }

    .buttons-wrapper {
        width: 280px;
        margin-bottom: 20px;

        @include funcion350px {
            margin-bottom: 10px;
        }

        @include funcion187px {
            flex-direction: column;
            align-items: center;

            button {
                margin-bottom: 10px;

                &:first-child:nth-last-child(n+2) {
                    margin-left: 0;
                }
            }
        }
    }

    .btn-imagenes-lugar {
        line-height: 16px;
    }

    .btn-imagenes-videos {
        line-height: 16px;
        padding: 12px;
        width: 160px;
    }

    @include funcion350px {
        p {
            margin-bottom: 5px;
        }
    }

    svg {
        margin-bottom: 10px;
    }
}


.evento-seleccionado-modal {
    iframe {
        width: 100%;
        height: 375px;
    }

    .mapa-wrapper {
        min-height: 400px;
    }

    .modal-body {
        padding: 0;
    }

    &.fotos {
        .modal-body {
            height: auto;
            max-height: 90vh;
        }
    }
    .modal-content {
        margin-top: 10px;
    }

    .eev-video {
        width: 100%;
        height: auto;
        display: block;
        margin: 0 auto;
    }
}

}