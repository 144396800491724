@import './variables.scss';
@import './mixins.scss';

.App_mobile .footer{
    background-color: $dark-color;
    height: auto;

    .social-wrapper{
        background-color: $main-color;
        height: 60px;
        button{
            border-radius:20px;
            width: 35px;
            height: 35px;
            @media (max-width: 400px) {
                width: 30px;
                height: 30px;
            }
            text-align: center;
            position: relative;
            margin: 0 15px;
            background-color: transparent;
            //background-color: #FFF;
            border: 2px solid #FFF;
            .icon{
                border: none;
                position: absolute;
                top:6px;
                left: 6px;
                width:20px;
                height: 20px;
                @media (max-width: 400px) {
                    width:15px;
                    height: 15px;
                }
            }

            .twitter-icon{
                width: 30px;
                left:1px;
                @media (max-width: 400px) {
                    width:24px;
                }
            }
        }
        @include funcion292px {
            height: 35px;
            padding-top: 6px;
            button{
                width: 20px;
                height: 20px;
                padding: 0;
                margin-top:-5px;
                .icon{
                    top:2px;
                    left:2px;
                    width: 12px;
                    height: 12px;
                }
            }
        }

        @include funcionLandscape{
            height: 40px;
            button{
                height: 25px;
                width: 25px;
                padding: 0;
                .icon{
                    width: 18px;
                    height: 18px;
                    top:3px;
                    left: 3px;
                }
            }
        }
    }

    .sub-footer{
        font-size: 0.80em;
        color:#FFF;
        height: auto;
        background-color: $dark-color;
        padding-bottom: 45px;
        .h5{
            font-size: 1.5rem;
            padding-top: 5px;
            @include funcion350px {
                font-size: 1rem;
            }
            @include funcion292px {
                font-size: 0.7rem;
            }

            @include funcionLandscape{
                font-size: 1rem;
            }
        }
        .btn-link{
            color: #FFF;
            padding: 0 5px;
            white-space: nowrap;
            text-decoration: none;
            font-size: 1em;
            @include funcion292px{
                font-size: 0.6rem;
                padding: 2px;
            }
        }
        .link-productores {
            @include funcion350px {
                font-size: 1.2rem
            }
            @include funcion292px {
                font-size: .9rem
            }
        }
        @include funcion350px {
           font-size: 0.62rem
          }

        @include funcion235px{
            font-size: 0.45rem;
        }

        @include funcionLandscape{
            font-size: 0.6rem;
        }
        .defensa{
            @include funcion292px{
                margin-bottom: 2px;
            }
        }
        .derechos{
            font-size:0.9rem;
            @include funcion292px{
                font-size: 0.6rem;
            }
        }
    }
}