@import './variables.scss';

.App_desktop {
  .modal-backdrop.show {
    opacity: 0.7;
  }

  .modal-header {
    background-color: $main-color;
    align-items: center;
    padding: 0;
    display: flex;
    align-items: center;
    height: 80px;
    justify-content: center;

    h5 {
      flex: 1;
      text-align: center;
      color: #FFF;
      margin: 0;
      font-size: 1.45rem;
      font-weight: bold;
    }

    .btn-close {
      position: absolute;
      right: 15px;
      top: -50px;
      background: none;
      opacity: 1;
    }

    .btn-close:before,
    .btn-close:after {
      position: absolute;
      left: 15px;
      content: ' ';
      height: 33px;
      width: 4px;
      background-color: #ffffff;
    }

    .btn-close:before {
      transform: rotate(45deg);
    }

    .btn-close:after {
      transform: rotate(-45deg);
    }

  }

  .modal-body {
    max-height: 50vh;
    overflow: auto;

    p {
      font-size: 1rem;
    }
  }

  @media (max-height: 679px) { 
    .modal-content {
      margin-top: -25px!important;
    }
    .modal-body {
      max-height: 68vh;
    }
  } 

  .ayuda {
    .modal-header {
      height: 60px;
    }
  }

  .modal-content{
    margin-top:-100px
  }

  .modal-compra-evento-seat{
    .modal-content{
      margin-top:0;
    }
  }
  .ayuda-online {
    button {
        border-radius: 20px;
        font-size: 1.2em;
    }
  }
}