@import './variables.scss';
@import './mixins.scss';
.pay-success{
    .title{
        font-size: 2.2em;
    }
    .button-container{
        background-color: $main-color;
        color: #FFF;
        border-radius: 10px;
        padding: 15px;
    }
}

.codigo-mercado-pago{
    font-size:2rem;
    @include funcion350px{
        font-size: 1rem;
    }
}