@import './variables.scss';

.home_desktop {
    background-color: #4c2371;
    background-repeat: no-repeat;
    background-size: cover;

    .date-range-icon {
        margin-top: 8px;
    }

    input {
        font-size: 1.2em;
    }

    .home-subtitle {
        margin-top: 1.5rem!important;
    }

    .logo {
        max-width: 300px;
    }

    @media (max-height: 570px) { 
        .logo{ 
            max-width: 250px;
        }
        .home-subtitle {
            margin-top: 0.9rem!important;
            h3 {
                font-size: calc(1.05rem + 0.6vw);
            }
        }

    }   

    .react-datepicker-wrapper {
        top: -4px;
        position: relative;
        left: -3px;
    }

    .react-datepicker__triangle {
        left: 15px !important
    }

    .buscador {
        padding-top: 5px;
        padding-bottom: 5px;
        max-width: 600px;
        flex: 1;

        svg {
            color: $primary-color;
            cursor: pointer;
            font-size: 2rem;
        }
        .btn-search {
            svg {
                font-size: 2.5rem;
            }
            
        }
        .btn-search:active {
            background-color: #FFF!important;
        }
    }

    .date-input {
        position: absolute;
        opacity: 0;
        width: 1px;
        height: 1px;
        padding: 0;
    }

    .resultados-busqueda {
        .dropdown-menu.show {
            transform: translate3d(0, 0, 0) !important;
            max-height: 50vh;
            min-height: 100px;
            overflow: auto;
            width: 100%;
            padding: 0px;

            ul {
                list-style-type: none;
                margin: 0;
                padding: 0;

                ul {
                    li {
                        padding: 5px 10px 5px 20px;
                        cursor: pointer;
                        &:hover {
                            background-color: $main-color;
                            color: #FFF;
                        }

                    }
                }

                li {
                    font-size: 0.8rem;
                    padding: 0;

                    p {
                        margin: 0;
                        padding: 10px;

                        &.text-muted {
                            margin-left: 15px;
                            font-size: 1rem;
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }

}