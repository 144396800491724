@import './variables.scss';


.cookie-modal-body {
    height: auto;
}

.cookie-policy{
    background-color: $primary-color;
    p {
        font-size: 1rem;
    }
    .btn{
        background-color: $dark-color;
        &:hover{
            background-color: $main-color;
        }
    }
}