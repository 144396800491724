.compra-paso-tres{
    .steps-buttons{
        button{
            width: 360px;
            margin-bottom: 20px;
        }
    }
    #fieldsetProvincia{
        label{
            z-index: 10;
        }
        .dropdown-toggle{
            height: 40px;
        }

        .select.dropdown-toggle{
            border: 1px solid  var(--bs-border-color);
            padding-top:8px;

            span{
                width: 95%;
                display: block;
                overflow: hidden;
            }

            &::after{
                position: absolute;
                right: 1px;
                top:7px
            }
        }
    }

    #fieldsetMunicipio{
        label{
            z-index: 10;
        }
        .dropdown-toggle{
            height: 40px;
        }

        .select.dropdown-toggle{
            border: 1px solid  var(--bs-border-color);
        }
    }

    .options{
        height: 300px;
        overflow-y: auto;
    }

}