@import './variables.scss';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import './mixins.scss';

.barra_superior_evento svg{
    font-size: 2rem;
    margin-right: 5px;
}
.h4.titulo_evento_en_paso {
    margin-top: 30px;
    margin-bottom: 10px;
    text-align: center;
    font-size: 2.2rem!important;
    line-height: 1;

    p {
        line-height: 0.6;
        font-size: 2.2rem!important;
    }

    @include funcion350px {
        margin-top: 15px;
        margin-bottom: 15px;
        font-size: 1.2rem !important;
    }
}

.time-date-wrapper{
    padding-bottom: 40px;
    font-weight: bold;
    svg{
        font-size: 1.5rem;
        margin-right:5px;
        color: #f60;
        margin-left:10px;
    }
}

.subtitles {
    color: #000 !important;
    font-size: 17px !important;
    background-color: #ddd;
    padding: 5px 15px;
    font-weight: 700;
}

.steps-buttons button {
    margin-bottom: -2px;
    width: 360px;
    font-size: 1rem;
    padding: 15px 0;
}

.detalle-compra-table .ant-table th.ant-table-cell {
    background: #fff;
    font-weight: 700;
    text-align: center !important;
    text-transform: uppercase;
}

.detalle-compra-table .ant-table .ant-table-cell {
    border-bottom: 1px solid #e7e7e7;
    border-right: 1px solid #e7e7e7;
    font-size: .8rem;
    padding: 5px;
    text-align: left;
}

.medios-de-pago {
    background-color: #221238;
    font-size: .7rem;
}

.total-detalle-compra {
    border-bottom: 1px solid #e7e7e7;
    font-size: .9rem;
    font-weight: 700;
    text-align: left;
}

.termina-comprador svg {
    color: #f60;
}

.pagar-decidir {
    font-size: 1.4rem;
    height: 40px;
    line-height: 0px;
    margin: 0;
    padding: 0;
    width: 260px;
}
#card-container .input-group-text {
    background: #fff;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    padding: 0;
}