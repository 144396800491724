@import '../variables.scss';
@import '../mixins.scss';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';


.App_mobile {

    .modal-compra-evento-plural .ant-table-cell,
    #sitwidget-confirm .price {
        input[type='radio'] {
            border: 1px solid #000;
            width: 1em;
            height: 1em;
            padding: 10px;
            background: #fff;
            margin-right: 5px;
            appearance: none;
            border-radius: 50%;

            &:checked {
                background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%272%27 fill=%27%230d6efd%27/%3e%3c/svg%3e');
            }
        }
    }


    .modal-compra-evento-plural,
    .modal-compra-evento-seat {
        .modal-body {
            margin-bottom: 18px;
            h5 {
                font-size: 1.35rem;
                @include funcion350px {
                    font-size: 1.25rem;
                }
            }
            p {
                font-size: 1.2rem;
                @include funcion350px {
                    font-size: 1rem;
                }
            }
            .ant-table-cell {
                font-size: 1.2rem;
                @include funcion350px {
                    font-size: 1rem;
                }
            }
        }

        .btn {
            font-size: 1.2rem;
            padding: 5px 15px;

            @include funcion350px {
                font-size: 1rem;
            }

            @include funcion235px {
                font-size: 0.8rem;
            }
        }
    }

    .compra-plural-bt {
        font-size: 1.4rem;
        padding: 10px;
        font-weight: bold;

        @include media-breakpoint-up(xs) {
            width: 100%
        }

        @include media-breakpoint-up(sm) {
            width: 400px;
        }

        @include funcion350px {
            font-size: 1.1rem;
        }

        @include funcion292px {
            padding: 7px;
            font-size: 1rem;
        }

        @include funcion187px {
            font-size: 0.7rem;
        }
    }


    .compra-paso-dos {
        #sitwidget {
            z-index: 10 !important;
            pointer-events: all;
            touch-action: manipulation;
        }

        @include funcion292px {
            p {
                margin-bottom: 3px;
            }
        }
    }

    @include funcionLandscape {
        #sitwidget-confirm {
            flex-direction: column;
            display: flex;
            align-items: center;
            justify-content: center;
            
            .modal-dialog-centered {
                width: 100%;
                margin-top: -155px;

                .modal-header{
                    .btn-close{
                        top:15px;
                        right: 30px;
                    }
                }
            }
        }
    }

    #sitwidget-confirm {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100vh;
        background: rgba(0, 0, 0, 0.7);
        border-radius: 0;
        margin: 0;

        h5 {
            font-size: 1.10rem;
        }

        .modal-dialog-centered {
            background-color: #FFF;
            top: 20%;
            border-radius: 0.5rem;

            @include funcion350px {
                top: 16%;
                margin-left: -5px;
                margin-right: -5px;
            }

            @include funcionLandscape {}
        }

        .modal-header {
            padding: 20px;
            border-top-left-radius: 0.5rem;
            border-top-right-radius: 0.5rem;
        }

        .info {
            font-weight: normal;

            @include funcion350px {
                font-size: 1rem;
            }
        }

        hr {
            margin: 0;
            max-width: 50%;
            margin-left: auto;
            margin-right: auto;

            @include funcion350px {
                max-width: 75%;
            }
        }

        .etiqueta-confirma {
            color: #666;
            font-size: 1.1rem;
            font-weight: normal;

            @include funcion350px {
                font-size: 1rem;
            }
        }

        .price {
            text-align: center;

            @include funcion350px {
                padding: 0px;
                margin-top: 10px
            }

            .descr {
                width: 80%;
                font-size: 16px;

                @include funcion350px {
                    width: 90%;
                    position: relative;
                    top: -4px
                }

                .label {
                    font-size: 16px;

                    @include funcion350px {
                        font-size: 14px;
                    }
                }

                .value {
                    @include funcion350px {
                        font-size: 14px;
                    }
                }
            }

            @include funcion350px {
                input[type=radio] {
                    width: 8px;
                    margin-right: 0;
                }
            }
        }
        .price_selector {
            .amount {
                width: 105px
            }
        }
        .cancel {
            background-color: $main-color;
        }

        svg {
            font-size: 1rem;
        }

        @include funcion350px {
            .body {
                padding-top: 10px;
                min-height: 100px;
            }

            .modal-header {
                height: 60px;
            }
        }



    }

    .sitwidget-controls {
        @include funcion350px {
            right: 15px;
            margin-top: -16px;

            button {
                width: 35px;
                height: 35px;
            }
        }

        @include funcionLandscape {
            right: 60px;
        }
    }

    .seat-widget {
        
        margin-right: 4px;
        margin-left: 4px;
        @include funcion350px {
            margin-top: 20px;
        }
        .sitwidget-container {
            position: relative;
            overflow: hidden;
            overscroll-behavior: none;
            touch-action: none; 
        }
    }

    #sitwidget-confirm .modal-header {
        padding: 10px;
    }

    .siguiente-paso {
        font-weight: bold!important;
    }

}