@import './variables.scss';
@import './mixins.scss';

.App_mobile {
    .header {
        background-color: $main-color;
        min-height: 65px;

        @include funcionLandscape{
            min-height: 30px;
        }

        @include funcion350px {
            min-height: 50px;
        }

        @include funcion292px{
            min-height: 20px;
        }

        .btn-abrir-cartelera {
            border: 2px solid #FFF;
            border-radius: 10px;
            width: 153px;
            height: 40px;
            @include funcionLandscape{
                height: 28px;
                width: 130px;
                margin-top: 2px;
            }

            span {
                color: #FFF;
                font-size: 16px;
                white-space: nowrap;
                padding: 0;
                @include funcionLandscape{
                    font-size: 11px
                }
            }

            @include funcion350px {
                width: 126px;
                height: 25px;
                border: 2px solid #FFF;
                padding: 15px;

                span {
                    font-size: 0.8rem;
                }
            }

            @include funcion292px{
                width: 105px;
                height: 30px;
                span{
                    font-size: 0.7rem;
                }
            }

            @include funcion187px {
                width: 80px;
                padding: 8px;

                span {
                    font-size: 0.47rem;
                }
            }
        }

        .help-icon {
            font-size: 2.2em;

            @include funcion350px {
                font-size: 1.9rem;
            }

            @include funcion292px{
                font-size: 1.6rem;
            }

            @include funcion187px {
                font-size: 1rem;
            }

            @include funcionLandscape{
                font-size: 1.3rem;
            }
        }

        .logo {
            max-height: 45px;
            margin-bottom: 10px;
            margin-top: 5px;
            max-width: 140px;

            @include funcion350px {
                max-width: 100%;
            }
        }

        &.fija {
            position: fixed;
            width: 100%;
            z-index: 2;
        }

        .spinner-wrapper {
            height: 200px;

            @include funcion350px {
                height: 120px;

                .lds-spinner {
                    transform: scale(0.7, 0.7);
                }
            }
        }
    }

    .dd-menu{
        margin-top: 40px;
        svg{
            margin-top:-40px
        }

       .btn-abrir-cartelera{
        margin-top:-40px
       }
    }

    .submenu {
        background-color: #241968;
        color: white;
        width: 200px;
        height: auto;
        overflow: auto;
        border-radius: 0;
        border-bottom: 1px solid #FFF;
        border-right: 1px solid #FFF;
        max-height: calc(100vh - 65px);
        padding-bottom: 20px;
        padding-top: 0;

        .submenu-title {
            background-color: #FFF;
            color: #241968;
            padding-top: 5px;
        }

        a {
            color: #FFF;
            text-decoration: none;
            font-size: 0.9em;
        }

        @include funcion350px {
            width: 170px;
            font-size: 0.9rem;
            margin-top: 5px;
            min-width: 120px;

            .submenu-title {
                font-size: 0.9em;

                svg {
                    font-size: 2em;
                }
            }
        }

        @include funcion292px{
            .submenu-title{
                font-size: 0.8em;
            }
        }

        @include funcion187px {
            width: 90px;
            font-size: 0.5rem;
        }
    }
}