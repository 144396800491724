@import './variables.scss';
@import './mixins.scss';

.App_mobile {
    .home {
        background-color: #4c2371;
        background-repeat: no-repeat;
        background-size: cover;

        .date-range-icon {
            margin-top: 8px;
        }

        input {
            font-size: 1.1em;

            @include funcion350px {
                font-size: 1.04rem;
            }

            @include funcion292px{
                font-size: 0.90rem;
            }

            @include funcion235px {
                font-size: 0.75rem;
            }

            @include funcion187px {
                font-size: 0.5rem;
            }

            @include funcionLandscape {
                font-size: 0.9rem;
            }
        }

        .logo {
            @include funcion292px {
                margin-top: 20px;
            }

            @include funcionLandscape {
                max-width: 170px;
                display: block;
                margin-left: auto;
                margin-right: auto;
                margin-top: 20px;
                margin-bottom: 0 !important;
            }
        }

        .react-datepicker-wrapper {
            top: -4px;
            position: relative;
            left: -3px;

            @include funcion350px {
                top: -2px;
                left: -2px;
            }
        }

        .react-datepicker__triangle {
            left: 15px !important
        }

        .buscador {
            padding-top: 5px;
            padding-bottom: 5px;

            svg {
                color: $primary-color;
                font-size: 1.85rem;
            }

            @include funcion292px {
                margin-top: -20px;
                margin-bottom: 10px;

            }

            @include funcion187px {
                padding: 2px;
            }

            @include funcion350px {
                padding: 4;

                svg {
                    font-size: 1.5rem;
                    margin-top: -5px;
                }

                .date-range-icon {
                    margin-top: 3px;
                }
            }

        }


        .date-input {
            position: absolute;
            opacity: 0;
            width: 1px;
            height: 1px;
            padding: 0;
        }

        .resultados-busqueda {
            .dropdown-menu.show {
                transform: translate3d(0, 0, 0) !important;
                max-height: 50vh;
                min-height: 100px;
                overflow: auto;
                width: 100%;
                margin-bottom:35px;
                ul {
                    list-style-type: none;
                    margin: 0;
                    padding: 0;

                    ul {
                        li {
                            padding: 5px 10px 5px 20px;
                        }
                    }

                    li {
                        font-size: 0.8rem;
                        padding: 0;

                        p {
                            margin: 0;
                            padding: 10px;

                            &.text-muted {
                                margin-left: 15px;
                                font-size: 1rem;
                            }
                        }
                    }
                }
            }

            @include funcion350px {
                .dropdown-menu.show ul li {
                    p {
                        font-size: 12px !important;
                        margin-bottom: 5px;
                        &.text-muted {
                            font-weight: bold;
                        }
                    }

                    ul {
                        margin-bottom: 5px;

                        li {
                            font-size: 12px !important;
                        }
                    }
                }

            }
        }
    }

}