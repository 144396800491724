@import './variables.scss';
@import './mixins.scss';

.App_desktop .footer{
    background-color: $dark-color;
    height: auto;

    .social-wrapper{
        background-color: $main-color;
        height: 60px;
        button{
            border-radius:20px;
            width: 35px;
            height: 35px;
            @media (max-width: 400px) {
                width: 30px;
                height: 30px;
            }
            text-align: center;
            position: relative;
            margin: 0 15px;
            background-color: $primary-color;
            border:0;
            .icon{
                position: absolute;
                top:8px;
                left: 8px;
                width:20px;
                height: 20px;

                &.ig{
                    margin-left: -1px;
                    margin-top:-1px;
                }
            }
            .twitter-icon{
                width: 40px;
                left:-2px;
            }
        }
        @include funcion292px {
            height: 30px;
            button{
                width: 20px;
                height: 20px;
                padding: 0;
                margin-top:-5px;
                .icon{
                    top:5px;
                    left:5px;
                    width: 10px;
                    height: 10px;
                }
            }
        }
    }

    .sub-footer{
        font-size: 0.80em;
        color:#FFF;
        height: auto;
        background-color: $dark-color;
        padding-bottom: 10px;
        .h5{
            padding-top: 5px;
            @include funcion292px {
                font-size: 0.7rem;
            }
        }
        .btn-link{
            color: #FFF;
            padding: 0 5px;
            white-space: nowrap;
            text-decoration: none;
            font-size: 1em;
            font-weight: normal;
        }
        @include funcion350px {
           font-size: 0.62rem
          }

        @include funcion235px{
            font-size: 0.45rem;
        }
    }
}