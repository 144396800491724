@import '../mixins.scss';

.App_desktop {
    .compra-paso-uno {
        @include funcion350px {
            margin-left: -10px;
            margin-right: -10px;
        }

        @media (max-width: 220px) {
            --bs-gutter-x: 5px;
            overflow-x: hidden;
        }
    }

    .no-image-no-description {
        .event-data {
            display: flex;

            .columna-event-data {
                width: 49%;
                margin-left: 1%;
            }
        }
    }

}