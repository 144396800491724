@import '../variables.scss';
@import '../mixins.scss';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';



.App_desktop {

    .modal-compra-evento-plural .ant-table-cell,
    #sitwidget-confirm .price {
        input[type='radio'] {
            border: 1px solid #000;
            width: 1em;
            height: 1em;
            padding: 10px;
            background: #fff;
            margin-right: 5px;
            appearance: none;
            border-radius: 50%;
          &:checked{
            background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%272%27 fill=%27%230d6efd%27/%3e%3c/svg%3e');
          }
        }
    }


    .modal-compra-evento-plural,
    .modal-compra-evento-seat {
        .btn {
            font-size: 1.2rem;
            padding: 5px 15px;

            @include funcion350px {
                font-size: 1rem;
            }

            @include funcion235px {
                font-size: 0.8rem;
            }
        }
        .cancel-bt{
            background-color: $main-color;
        }
    }

    .modal-compra-evento-seat{
        max-width: 500px;

        .price_selector{
            text-align: left;
            tr{
                margin-left: auto;
                margin-right: auto;
                display: block;
                width: 390px;

                .descr{
                    padding: 0 15px;
                }

                .amount{
                    width: 87px;
                }
            }
        }
    }

    .compra-plural-bt {
        font-size: 1.4rem;
        padding: 10px;

        @include media-breakpoint-up(xs) {
            width: 100%
        }
    }


    .compra-paso-dos {
        #sitwidget {
            z-index: 10 !important;
        }

        .sitwidget-container {
            position: relative;
            overflow: hidden;
        }

        @include funcion292px {
            p {
                margin-bottom: 3px;
            }
        }
    }
/*
    #sitwidget-confirm .price {
        input[type='radio'] {
            margin-right: -25px;
            position: absolute;
        }
    }*/

    #sitwidget-confirm {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100vh;
        background: rgba(0, 0, 0, 0.7);
        border-radius: 0;
        margin: 0;

        .modal-dialog-centered {
            background-color: #FFF;
            top: 20%;
            border-radius: 0.5rem;

            @include funcion350px {
                top: 6%;
                margin-left: -5px;
                margin-right: -5px;
            }
        }

        .modal-header {
            padding: 20px;
            border-top-left-radius: 0.5rem;
            border-top-right-radius: 0.5rem;
        }

        .info {
            font-weight: bold;

            @include funcion350px {
                font-size: 1rem;
            }
        }

        hr {
            margin: 0;
            max-width: 50%;
            margin-left: auto;
            margin-right: auto;

            @include funcion350px {
                max-width: 75%;
            }
        }

        .etiqueta-confirma {
            color: #666;
            font-size: 1.1rem;
            font-weight: bold;

            @include funcion350px {
                font-size: 1rem;
            }
        }

        .price {

            .descr {
                width: 80%;
                font-size: 16px;

                @include funcion350px {
                    width: 90%;
                    position: relative;
                    top: -4px
                }

                .label {
                    font-size: 16px;

                    @include funcion350px {
                        font-size: 14px;
                    }
                }

                .value {
                    @include funcion350px {
                        font-size: 14px;
                    }
                }
            }

            @include funcion350px {
                input[type=radio] {
                    width: 8px;
                    margin-right: 0;
                }
            }
        }

        .cancel {
            background-color: $main-color;
        }

        svg {
            font-size: 1rem;
        }

        @include funcion350px {
            .body {
                padding-top: 10px;
                min-height: 100px;
            }

            .modal-header {
                height: 60px;
            }
        }

    }

    .sitwidget-controls {
        @include funcion350px {
            right: 15px;
            margin-top: -20px;

            button {
                width: 20px;
                height: 20px;
            }
        }
    }

    .seat-widget {
        
        @include funcion350px {
            margin-top: 20px;
        }
    }

    #sitwidget-confirm .modal-header {
        padding: 10px;
    }

}