@import './variables.scss';
@import './mixins.scss';

.App_mobile {
  .modal-backdrop.show {
    opacity: 0.7;
  }

  .modal-header {
    background-color: $main-color;
    align-items: center;
    padding: 0;
    display: flex;
    align-items: center;
    height: 80px;
    justify-content: center;

    h5 {
      flex: 1;
      text-align: center;
      color: #FFF;
      margin: 0;
    }

    .btn-close {
      position: absolute;
      right: 15px;
      top: -50px;
      background: none;
      opacity: 1;

      @include funcion350px {
        transform: scale(0.7) translate(10px, 25px);
      }

      @include funcionLandscape{
        transform: scale(0.7) translate(10px, 20px);
      }
    }

    .btn-close:before,
    .btn-close:after {
      position: absolute;
      left: 15px;
      content: ' ';
      height: 33px;
      width: 4px;
      background-color: #ffffff;
    }

    .btn-close:before {
      transform: rotate(45deg);
    }

    .btn-close:after {
      transform: rotate(-45deg);
    }


    @include funcion350px {
      height: 40px;

      h5 {
        font-size: 1.25rem;
      }
    }

    @include funcion187px {
      height: 30px;

      h5 {
        font-size: 0.7rem;
      }
    }

  }

  .modal-body {
    height: 50vh;
    overflow: auto;

    p {
      font-size: 1rem;
    }

    @include funcion350px {
      padding: 10px;

      h2 {
        font-size: 1.1rem;
      }

      p {
        font-size: 0.8rem;
      }
    }
  }

  .modal-footer {
    @include funcion350px {
      padding: 5px;
    }
  }

  .ayuda {
    .modal-header {
      height: 60px;
    }
    z-index: 99999;
  }

  .ayuda-online {
    button {
        border-radius: 20px;
        font-size: 1.2em;
        margin-bottom: 0px;

        @include funcion350px {
            font-size: 1rem;
            margin-bottom: 0px;
            padding: 7px;

            svg {
                font-size: 1.2rem;
            }
        }

        @include funcion235px {
            font-size: 0.7rem;
            margin-bottom: 20px;
            padding: 5px;
        }

        @include funcion187px {
            font-size: 0.5rem;
            margin-bottom: 15px;
            padding: 4px;

            svg {
                font-size: 0.9rem;
            }
        }

        @include funcionLandscape {
            margin-bottom: 5px;
            font-size: 0.8rem;

            svg {
                font-size: 0.8rem;
            }
        }

    }
}

}