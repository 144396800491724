@import './mixins.scss';

.App_desktop .time-out{
    min-height: calc(100vh - 268px);
    h1{
        font-size: 2.8rem;
    }

    button{
        font-size: 1rem;
        padding: 10px;
        svg{
            font-size: 1rem;
        }
    }
}