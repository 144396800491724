@import './variables.scss';
@import './mixins.scss';

html {
    -moz-text-size-adjust: none;
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
  }
.App_desktop{
    .btn{
        background-color: $primary-color;
        border: 0;
        &.btn-link{
            background-color: transparent;
        }
    }

    #root{
        overflow-x: hidden;
    }

    .back_1{
        background-color: #FFF;
    }
    .back_2{
        background-color: $main-color;
    }
    .back_3{
        background-color: $primary-color;
    }

    .text_1{
        color: #FFF;
    }
    .text_2{
        color: $main-color;
    }
    .text_3{
        color: $primary-color;
    }

    .fs-11{
        font-size:1.1em;
    }

    .fs-12{
        font-size:1.2em;
    }
    .fs-22{
        font-size:2.2em;
    }

    .down-icon{
        width: 30px;
        height: 30px;
        background-color: $primary-color;
        position: relative;
        right: 150px;
        color: #FFF;
        cursor: pointer;
        position: absolute;
        svg.MuiSvgIcon-root{
            color:#FFF;
            font-size:1.9em;
        }

        @include funcion350px{
            width: 17px;
            height: 17px;
            svg.MuiSvgIcon-root{
                font-size:1rem;
                position: relative;
                top:1px;
                left:0.5px;
            }
        }


    }
    .abajo-icon{
        width: 30px;
        height: 30px;
        background-color: $main-color;
        position: relative;
        right: 150px;
        color: #FFF;
        position: absolute;
        svg.MuiSvgIcon-root{
            color:#FFF;
            font-size:1.9em;
        }

        @include funcion350px{
            width: 17px;
            height: 17px;
            svg.MuiSvgIcon-root{
                font-size:1rem;
                position: relative;
                top:1px;
                left:0.5px;
            }
        }


    }
    input:focus {
        outline: none !important;
        box-shadow: none !important;
    }

    .btn:first-child:active, .btn:hover, .btn:focus-visible{
        background-color: $primary-color;
    }

    .general-title{
        font-size:1.8rem;
        @include funcion350px{
            font-size: 1rem;
        }
    }


    .social-wrapper .down-icon{
        @media (min-width: 12px) and (max-width: 177px) {
            margin-bottom:60px;
            right:-20px
        }
    }

    .btn{
        font-weight: bold;
    }

    p{
        @include funcion350px{
            font-size: 0.9rem;
        }
    }

    .modal-error{
        background: rgba(0,0,0, 0.5);
        height: 100vh;
        margin: 0;
        padding: 10px;
        width: 100%;
        max-width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .modal-content{
            max-width: 500px;

        }
    }

    .background-image-landing {
        position: fixed;
        top: 20px;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        z-index: -1;
      }
      
      .floating-button-landing {
        position: fixed;
        bottom: 200px;
        right: 200px;
        //left: 700px;
        z-index: 1;
        width: 18%;
        font-size: 1.4rem;
      }
}