.App_mobile {
    .aviso-seat{

        .modal-body{
            height:auto;

            button{
                width: 80%;
                font-size:1.2rem;
            }
        }
    }
}