@import './variables.scss';

.barra_superior_evento{
    background-color: $main-color;
    color: #FFF;
}

.App_desktop .evento-seleccionado .barra_superior_evento svg{
    font-size: 2rem;
    margin-right: 5px;
}